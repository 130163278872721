import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

const CHECK_AUTH = gql`
  query isAuthenticated {
    auth @client {
      isAuthenticated
      type
    }
  }
`;

class IsAuthenticated extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
  };

  render() {
    const { children, ...rest } = this.props;
    return (
      <Query query={CHECK_AUTH}>
        {({ data: { auth } }) =>
          children({
            isAuthenticated: auth.isAuthenticated,
            originalProps: rest,
            type: auth.type,
          })
        }
      </Query>
    );
  }
}

export default IsAuthenticated;
