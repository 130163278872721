import React from 'react';
import Loader from '../Loader';

export default function Loading() {
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <Loader fill="#38C172" />
    </div>
  );
}
