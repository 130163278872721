import React, { lazy, Component, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import AppStartup from '../AppStartup';
import PrivateRoute from '../PrivateRoute';
import Public from '../../pages/Public';

const AgentPage = lazy(() => import('../../pages/agent/AgentPage'));
const AdminPage = lazy(() => import('../../pages/admin/AdminPage'));

class App extends Component {
  render() {
    return (
      <AppStartup>
        <Suspense fallback={null}>
          <Switch>
            <PrivateRoute
              userType="AGENT"
              path="/agent"
              redirectPath="/members/login"
              component={AgentPage}
            />
            <PrivateRoute
              userType="ADMIN"
              path="/_af"
              redirectPath="/_af-login"
              component={AdminPage}
            />
            <Route path="/" component={Public} />
          </Switch>
        </Suspense>
      </AppStartup>
    );
  }
}

export default App;
