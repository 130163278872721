import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

const routes = [
  {
    path: '/',
    component: lazy(() => import('../home/HomePage')),
    name: 'Home',
  },
  {
    path: '/members/login',
    component: lazy(() => import('../AgentLogin')),
    name: 'Agent Login',
  },
  {
    path: '/members/new',
    component: lazy(() => import('../AgentRegister')),
    name: 'Agent Register',
  },
  {
    path: '/agent-verification/:verificationKey',
    component: lazy(() => import('../AgentVerification')),
    name: 'Agent Verification',
  },
  {
    path: '/members/forgotPassword',
    component: lazy(() => import('../AgentForgotPassword')),
    name: 'Forgot Password',
  },
  {
    path: '/members/resetPassword/:code',
    component: lazy(() => import('../AgentResetPassword')),
    name: 'Reset Password',
  },
  {
    path: '/_af-login',
    component: lazy(() => import('../AdminLogin')),
  },
  {
    path: '/privacy-policy',
    component: lazy(() => import('../home/PrivacyPolicy')),
    name: 'Privacy Policy',
  },
  {
    path: '/terms-and-conditions',
    component: lazy(() => import('../home/TermsAndConditions')),
    name: 'Terms and Conditions',
  },
  {
    path: '/faq',
    component: lazy(() => import('../home/FaqPage')),
    name: 'FAQ',
  },
  {
    path: '/about-us',
    component: lazy(() => import('../home/AboutUs/AboutUsPage')),
    name: 'About Us',
  },
  {
    path: '/blogs/what-is-a-commission-advance',
    component: lazy(() => import('../home/blogs/AdvanceRSC')),
    name: 'Advance on Real State Commission',
  },
  {
    path: '/blogs/commission-advance-myths',
    component: lazy(() => import('../home/blogs/FiveMyths')),
    name: 'Five Myths on Real State Commission Advances',
  },
  {
    path: '/blogs/commission-advance-benefits',
    component: lazy(() => import('../home/blogs/HowCABenefits')),
    name: 'Benefit from Commission Advances',
  },
];

export default function Public() {
  return (
    <Switch>
      {routes.map(route => (
        <Route
          exact
          key={route.path}
          path={route.path}
          component={route.component}
        />
      ))}
    </Switch>
  );
}
