import React, { Component } from 'react';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import Loading from '../Loading';

const VERIFY_TOKEN = gql`
  query verifyToken($accessToken: String!) {
    verifyToken(accessToken: $accessToken) {
      id
      accessToken
      expiresIn
      type
      refreshToken
      user {
        ... on Agent {
          id
          email
        }
      }
    }
  }
`;

const LOGIN_USER = gql`
  mutation loginUser($accessToken: String!) {
    loginUser(accessToken: $accessToken) @client
  }
`;

const LOGOUT_USER = gql`
  mutation logoutUser {
    logoutUser @client
  }
`;

const getToken = () =>
  localStorage ? localStorage.getItem('access_token') : null;

class AppStartup extends Component {
  state = { isLoading: true };

  componentDidMount() {
    this.checkToken();
  }

  checkToken = () => {
    const { client } = this.props;

    const token = getToken();

    if (token) {
      client
        .query({
          query: VERIFY_TOKEN,
          variables: { accessToken: token },
        })
        .then(result => {
          let authAction;
          if (result.data.verifyToken) {
            authAction = client.mutate({
              mutation: LOGIN_USER,
              variables: { accessToken: result.data.verifyToken },
            });
          } else {
            authAction = client.mutate({ mutation: LOGOUT_USER });
          }
          authAction.then(() => {
            this.setState({ isLoading: false });
          });
        })
        .catch(error => {});
    } else {
      this.setState({ isLoading: false });
    }
  };

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }
    return this.props.children;
  }
}

export default withApollo(AppStartup);
